import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import Login from "../views/auth/LoginPage.vue";

Vue.use(Router);
const beforeEnter = (to, from, next) => {
  if (store.state.user.isLoggedIn) {
    next();
  } else {
    next("/");//change to "/" when done testing
  }
};
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    name: "Login",
    path: "/",
    component: Login,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: () => import("@/views/dashboard/pages/home/Dashboard"),
    beforeEnter,
  },
  {
    name: "Users",
    path: "/users",
    component: () => import("@/views/dashboard/pages/users/UsersTable"),
    beforeEnter,
  },
  {
    name: "Customers",
    path: "/customers",
    component: () => import("@/views/dashboard/pages/customers/CustomersTable"),
    beforeEnter,
  },
  {
    name: "Tests",
    path: "/test",
    component: () => import("@/views/dashboard/pages/loans/TestTable"),
  },
  {
    name: "Active Loans",
    path: "/active-loans",
    component: () => import("@/views/dashboard/pages/loans/ActiveLoansTable"),
    beforeEnter,
  },
  {
    name: "Transactions",
    path: "/transactions",
    component: () =>
      import("@/views/dashboard/pages/transactions/TransactionTable"),
    beforeEnter,
  },
  {
    name: "Loan Disbursements",
    path: "/loan-disbursements",
    component: () =>
      import("@/views/dashboard/pages/loans/DisbursedLoansTable"),
    beforeEnter,
  },
  {
    name: "Loan Collections",
    path: "/loan-collections",
    component: () =>
      import("@/views/dashboard/pages/loans/CollectedLoansTable"),
    beforeEnter,
  },
  {
    name: "Organizations",
    path: "/organizations",
    component: () =>
      import("@/views/dashboard/pages/organizations/OrganizationsTable"),
    beforeEnter,
  },
];
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
