import API from "../../API.js";
import { REQUEST_METHODS } from "../../configs/";
export default {
  state: {
    requestError: null,
    transactions: [],
  },
  getters: {},
  mutations: {
    updateTransactionName(state, value) {
      state.id = value.toString().trim();
    },
    updateRequestError(state, value) {
      state.requestError = value;
    },
    updateTransactions(state, value) {
      state.transactions = value;
    },
    addTransaction(state, value) {
      state.transactions.data.push(value);
    },
    updateTransaction(state, value) {
      const transactionIndex = state.transactions.data.findIndex(
        (transaction) => transaction.id === value.id
      );
      state.transactions.data.splice(transactionIndex, 1, value);
    },
    deleteTransaction(state, value) {
      const transactionIndex = state.transactions.data.findIndex(
        (transaction) => transaction.id === value.id
      );
      state.transactions.data.splice(transactionIndex, 1);
    },
  },
  actions: {
    async fetchTransactions({ commit }) {
      await API(REQUEST_METHODS.GET, "/transactions?limit=10000")
        .then(( response ) => {
           console.log('TRANSACTIONS FETCHED ---->',response);
          commit("updateTransactions", response, { module: "transaction" });
        })
        .catch((err) => {
          console.log(err);
          // add it to transactions error message
          commit("updateRequestError", err.message, { module: "transaction" });
        });
    },
    async createTransaction({ commit }, item) {
      commit("updateRequestError", null, { module: "transaction" });
      await API(REQUEST_METHODS.POST, "/transactions", item)
        .then(({ createdTransaction }) => {
          commit("addTransaction", createdTransaction, { module: "transaction" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "transaction" });
        });
    },
    async updateTransaction({ commit }, item) {
      commit("updateRequestError", null, { module: "transaction" });
      await API(REQUEST_METHODS.PUT, `/transactions/${item.id}`, {
        username: item.newTransaction,
        password: item.password,
      })
        .then(({ updatedTransaction }) => {
          commit("updateTransaction", updatedTransaction, { module: "transaction" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "transaction" });
        });
    },
    async deleteTransaction({ commit }, id) {
      commit("updateRequestError", null, { module: "transaction" });
      await API(REQUEST_METHODS.DELETE, `/transactions/${id}`)
        .then(({ deletedTransaction }) => {
          commit("deleteTransaction", deletedTransaction, { module: "transaction" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "transaction" });
        });
    },
  },
  namespaced: true,
};
