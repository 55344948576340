import API from "../../API.js";
import { REQUEST_METHODS } from "../../configs/";
export default {
  state: {
    requestError: null,
    organizations: [],
  },
  getters: {},
  mutations: {
    updateOrganizationName(state, value) {
      state.id = value.toString().trim();
    },
    updateRequestError(state, value) {
      state.requestError = value;
    },
    updateOrganizations(state, value) {
      state.organizations = value;
    },
    addOrganization(state, value) {
      state.organizations.data.push(value);
    },
    updateOrganization(state, value) {
      const organizationIndex = state.organizations.data.findIndex(
        (organization) => organization.id === value.id
      );
      state.organizations.data.splice(organizationIndex, 1, value);
    },
    deleteOrganization(state, value) {
      const organizationIndex = state.organizations.data.findIndex(
        (organization) => organization.id === value.id
      );
      state.organizations.data.splice(organizationIndex, 1);
    },
  },
  actions: {
    async fetchOrganizations({ commit }) {
      await API(REQUEST_METHODS.GET, "/companies?limit=10000")
        .then(( response ) => {
          // console.log('ORGANIZATIONS FETCHED ---->',response);
          commit("updateOrganizations", response, { module: "organization" });
        })
        .catch((err) => {
          console.log(err);
          // add it to products error message
          commit("updateRequestError", err.message, { module: "organization" });
        });
    },
    async createOrganization({ commit }, item) {
      commit("updateRequestError", null, { module: "organization" });
      await API(REQUEST_METHODS.POST, "/companies", item)
        .then(({ createdOrganization }) => {
          commit("addOrganization", createdOrganization, { module: "organization" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "organization" });
        });
    },
    async updateOrganization({ commit }, item) {
      commit("updateRequestError", null, { module: "organization" });
      await API(REQUEST_METHODS.PUT, `/companies/${item.id}`, {
        username: item.newOrganization,
        password: item.password,
      })
        .then(({ updatedOrganization }) => {
          commit("updateOrganization", updatedOrganization, { module: "organization" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "organization" });
        });
    },
    async deleteOrganization({ commit }, id) {
      commit("updateRequestError", null, { module: "organization" });
      await API(REQUEST_METHODS.DELETE, `/companies/${id}`)
        .then(({ deletedOrganization }) => {
          commit("deleteOrganization", deletedOrganization, { module: "organization" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "organization" });
        });
    },
  },
  namespaced: true,
};
